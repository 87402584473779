import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Section,
  InnerMedium,
} from '../../Sections'
import Form from '../Form'
import SectionHeading from '../../SectionHeading'
import color from '../../../constants/colors'

const MainContainer = styled.div`
  background-color: ${color.silverBg};
  margin-bottom: 50px;
  padding: 50px 15px 80px;
  text-align: center;

  margin-bottom: ${(props) => (props.type === 'trial' ? '50px' : '0')};
`

const StyledHeading = styled(SectionHeading)`
  font-size: 2.4rem;
  margin: 20px 0;
`

const StyledForm = styled(Form)`
  background-color: pink;
`

const InquiryForm = ({ type }) => {
  const title = type === 'trial' ? '体験レッスンのお申し込み' : 'お問合せ'

  return (
    <>
      <Section id="inquiry-form">
        <InnerMedium>
          <MainContainer type={type}>
            <StyledHeading
              paddingY={0}
              withoutAnimation
            >
              { title }
            </StyledHeading>
            <StyledForm type={type} />
          </MainContainer>
        </InnerMedium>
      </Section>
    </>
  )
}

InquiryForm.propTypes = {
  type: PropTypes.string,
}

InquiryForm.defaultProps = {
  type: null,
}

export default InquiryForm
