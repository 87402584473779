import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import InquiryFormSection from '../components/pageSections/InquryForm'

const InquiryPage = ({ location: { pathname } }) => (
  <Layout>
    <SEO
      title="お問い合わせ　| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
      description="ご質問などございましたら、お気軽にこちらのフォームからお問い合わせください。"
      pathName={pathname}
    />
    <InquiryFormSection type="inquiry" />
  </Layout>
)

InquiryPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

InquiryPage.defaultProps = {
  location: null,
}

export default InquiryPage
